class MobileNavigation {
  constructor(navSelector, closeSelector, toggleSelector) {
    this.toggleClass = 'open';
    this.navEl = document.querySelector(navSelector);
    this.closeEl = document.querySelector(closeSelector);
    this.toggleEl = document.querySelector(toggleSelector);
  }

  init() {
    this.toggleEl.addEventListener('click', this.toggle.bind(this));
    this.closeEl.addEventListener('click', this.close.bind(this));
  }

  open(e) {
    e.preventDefault();
    this.navEl.classList.add(this.toggleClass);
  }

  close(e) {
    e.preventDefault();
    this.navEl.classList.remove(this.toggleClass);
  }

  toggle(e) {
    e.preventDefault();
    this.navEl.classList.toggle(this.toggleClass);
  }
}

let navigation = new MobileNavigation('.nav-mobile', '.nav-mobile .close', '.nav-mobile-toggle');
navigation.init();

export default navigation;