module.exports={
  "maxhours": 8,
  "steps": 1,
  "activeGrade": {},
  "schoolPackets": {},
  "schools": [
    {
      "id": 1,
      "name": "Campus Annuntiata Veurne",
      "schoolgroup": 1,
      "address": "Vleeshouwersstraat 22<br/> 8630 Veurne",
      "phone": "058 31 13 45",
      "email": "info@annuntiata.be",
      "website": "www.annuntiata.be",
      "logo": "logo-annuntiata.png",
      "selectable": false,
      "selected": false,
      "fix": false,
      "selectedPackets": []
    },
    {
      "id": 2,
      "name": "Campus College Veurne",
      "schoolgroup": 1,
      "address": "Karel Coggelaan 8<br/> 8630 Veurne",
      "phone": "058 31 14 74",
      "email": "info@cove.be",
      "website": "www.cove.be",
      "logo": "logo-college.png","selectable": false,
      "selected": false,
      "fix": false,
      "selectedPackets": []
    },
    {
      "id": 3,
      "name": "Campus Engineering Veurne",
      "schoolgroup": 1,
      "address": "Ieperse Steenweg 90<br/> 8630 Veurne",
      "phone": "058 31 15 09",
      "email": "info@vtiveurne.be",
      "website": "www.vtiveurne.be",
      "logo": "logo-vti.png",
      "selectable": false,
      "selected": false,
      "fix": false,
      "selectedPackets": []
    },
    {
      "id": 4,
      "name": "Campus Immaculata De Panne",
      "schoolgroup": 2,
      "address": "Koninklijke Baan 28<br/> 8660 De Panne",
      "phone": "058 41 15 79",
      "email": "info@immaculatainstituut.be",
      "website": "www.immaculatainstituut.be",
      "logo": "logo-immaculata.png",
      "selectable": false,
      "selected": false,
      "fix": false,
      "selectedPackets": []
    },
    {
      "id": 5,
      "name": "Campus SBC Nieuwpoort",
      "schoolgroup": 3,
      "address": "Marktplein 5<br/> 8620 Nieuwpoort",
      "phone": "058 23 31 03",
      "email": "info@sintbernarduscollege.be",
      "website": "www.sintbernarduscollege.be",
      "logo": "logo-bernardus.png",
      "selectable": false,
      "selected": false,
      "fix": false,
      "selectedPackets": []
    }
  ],
  "packets": [
    {
      "id": 1,
      "name": "Creatief atelier",
      "text" : "Ben je in de ban van kunst en cultuur? Wil je via actuele projecten nieuwe stappen zetten in je artistieke ontwikkeling? Volg dan Creatief atelier. Je kunt er aan de slag met de meest uiteenlopende materialen zoals karton, textiel, recyclagematerialen, ? Daarnaast maak je stap voor stap ook kennis met verschillende expressievormen zoals beeld, muziek en media. Verras jezelf en beleef!",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 2,
      "name": "Klassieke talen",
      "text": "Is de Griekse en Romeinse cultuur jouw ding? Ben je graag met taal bezig? Dan is Klassieke talen zeker iets voor jou. Via korte verhalen in het Latijn ga je op ontdekkingstocht doorheen de rijke geschiedenis en mythologie van de oudheid. Elke dag ga je aan de slag met alles wat deze taal je te bieden heeft. Daarnaast helpt de kennis van Latijn je bij het studeren van andere talen. Kom en laat je verwennen door Grieken, Romeinen en hun taal.",
      "hours": 4,
      "types": [
        "fix"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 3,
      "name": "Economie & IT",
      "text": "Wil je nu al je eerste stappen in het bedrijfsleven zetten? Handel, boekhouding, bankwereld, kantoor, reclame, media en IT, rinkelt er bij jou een belletje? Kom dan naar Economie & IT. Je leert hoe een onderneming werkt. Je gaat op verkenning bij de handelaar om de hoek en je onderzoekt de wereld van de marketing. Met je eigen project ontdek je dat economie ook IT nodig heeft: apps, websites, data, rekenbladen, presentatiesoftware,... Stap je mee in?",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 4,
      "name": "Samen & leven",
      "text": "Heb je interesse in het doen en laten van de mens? Wil je meer weten over hoe mensen met elkaar omgaan? Dan zit je goed bij Samen & leven. Je leert er observeren en communiceren. Je kijkt hoe de mens zich goed kan voelen in de maatschappij. Daarnaast onderzoek je ook hoe je een persoonlijke en gezonde levensstijl kan versterken. Ontdek je dit met ons mee?",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 5,
      "name": "Taalatelier",
      "text": "Vind je talen spreken ook zo cool? In Taalatelier kan je Frans en Engels leren zonder dat je het beseft. Je speelt spelletjes, je bekijkt filmpjes, je werkt in groep, je maakt een quiz. Je leert de talen in levensechte situaties. Je leert streekspecialiteiten kennen, zowel in de klas als in de keuken. Zo proef je ook van de Franse en Engelse cultuur. Envie? Come and join us!",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 6,
      "name": "Sport & wetenschappen",
      "text": "Doet sport jouw hart sneller slaan? Hou je van bewegen? Dan is Sport & wetenschappen echt iets voor jou. Je probeert niet alleen nieuwe sporten uit. Je ontdekt ook de effecten van sport op je lichaam. Je zoekt naar de link tussen sport en bloedsomloop, sport en zwaartekracht, sport en ideale voeding en nog zo veel meer. Ben je al opgewarmd?",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 7,
      "name": "Stem & IT",
      "text": "Hou je van een uitdaging en werk je graag met IT-tools? Wil je leren programmeren, een eigen app ontwerpen, een website bouwen, een eigen game ontwikkelen, ...? Dan is STEM & IT een goede keuze voor jou. We denken eerst na over wat we willen maken, welk probleem we willen oplossen en hoe we dit zullen aanpakken. De LEGO Education Innovation Studio biedt hiervoor veel mogelijkheden. Iets voor denkers en doeners dus!",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 8,
      "name": "Stem & technieken",
      "text": "Ben je gebeten door techniek? Ga je het liefst verkennend aan de slag met materialen zoals hout, metaal en kunststof? Wil je ook iets bijleren over elektriciteit? Dan is STEM & technieken iets voor jou. Een pakket voor doeners die graag problemen aanpakken, zonder daarbij de theoretische achtergrond uit het oog te verliezen. Experimenteer je met ons mee?",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 9,
      "name": "Stem & wetenschappen",
      "text": "Nieuwsgierig naar engineering en technologie? Naar biologie en chemie? Niet bang van een beetje wiskunde? Ben je graag actief bezig met onderzoeken en experimenteren? Vraag je je af hoe de wereld rondom jou in mekaar zit? Dan is STEM & wetenschappen the place to be. Word jij ook lid van ons onderzoeksteam? ",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 10,
      "name": "Voedingsatelier",
      "text": "Een appeltaart bakken, een smoothie maken, een ovenschotel bereiden, wil je er al onmiddellijk aan beginnen? Vind je lekker &eacute;n gezond eten belangrijk? Dan is Voedingsatelier jouw keuken. Je maakt een waaier aan gerechtjes klaar. Je leert welke ingredi&euml;nten je nodig hebt, hoe je een gerecht klaarmaakt en presenteert. En er staat nog meer op het programma: de voedingsdriehoek, bewaren van voeding, een etiket lezen, ? Dek je alvast de tafel met ons mee?",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 11,
      "name": "Voeding & horeca",
      "text": "Ben je gepassioneerd door de keuken? Ga je graag creatief aan de slag? In Voeding & horeca krijg je de kans om te proeven van de verschillende voedingssectoren. Je verlegt je grenzen in de horeca. Je werkt aan hartelijke discipline, gastvrijheid en nog veel meer. Je bent fier op je eigen werk in de keuken, maar ook op wat je als team met de andere koks kan bereiken. Welkom in de wereld van de fijnproeverij!",
      "hours": 5,
      "types": [
        "fix"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 12,
      "name": "Wiskundelab",
      "text": "Ben je gefascineerd door de wondere wereld van wiskunde en haar oneindige aspecten? Besef je dat wiskunde overal om je heen zit? Welkom in het Wiskundelab! Je vindt er uitdagingen, toepassingen van wiskunde in het dagelijkse leven, spelvreugde en nog veel meer. We proberen onze wereld beter te begrijpen en te interpreteren. We zoeken naar inzicht en abstract denken en we maken kennis met softwarepakketten. We rekenen op jou!",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 13,
      "name": "Crea & zorg & voeding & ondernemen",
      "text": "Draag je graag zorg voor jezelf en de mensen om je heen? Ben je creatief en ondernemend? Wil je meer weten over een gezonde levensstijl? Dan zul je je hier thuis voelen! Je krijgt in dit pakket creatieve en expressieve uitdagingen. Je maakt lekkere gerechtjes klaar volgens de knepen van het vak. Je ontdekt jezelf als ondernemer. Doe mee, ervaar, ontdek, beleef... Proef je mee van het spannende leven?",
      "hours": 4,
      "types": [
        "fix"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 14,
      "name": "Bouw & hout & mechanica & elektriciteit",
      "text": "Ga je graag aan de slag met gereedschap? Werk je graag met verschillende soorten materialen? Dan zit je hier goed! Hier ben je creatief en experimenteer je met hout, metaal, kunststof en elektriciteit. Monteer, bewerk, verbind en nog zoveel meer. Je maakt zelf een afgewerkt product van begin tot einde. Bouw jij mee aan iets nieuws?",
      "hours": 4,
      "types": [
        "fix"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 15,
      "name": "Taal & wiskunde Plus",
      "text": "Wil je meer lestijd voor Nederlands, Frans en wiskunde? Wil je leren hoe jij deze vakken het best aanpakt? Dan zit je goed bij Taal & wiskunde Plus. Je krijgt extra uitleg en oefeningen op jouw maat. We zoeken samen naar de aanpak die voor jou de grootste leerwinst geeft. Meteen ook een pluspunt voor al je andere vakken! Plus je met ons mee?",
      "hours": 2,
      "types": [
        "fix"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 16,
      "name": "STEM & biotechnologische wetenschappen",
      "text": "Ben je geboeid door natuurwetenschappen? Wil je op zoek gaan naar de verbanden tussen biologie, chemie, fysica en wiskunde? Wil je een wetenschappelijke verklaring voor de wereld rondom jou? Wil je weten hoe de natuur om je heen werkt? In STEM & biotechnologische wetenschappen zetten we de eerste stappen in het onderzoeken en experimenteren in het labo, startend vanuit een probleemstelling of een onderzoeksvraag. Experimenteer jij met ons mee?",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 17,
      "name": "STEM & technologische wetenschappen",
      "text": "Ben je geboeid door wetenschappen, technologie, engineering en wiskunde? Schrikt probleemoplossend denken je niet af? Dan krijg je in STEM & technologische wetenschappen ruime kansen om jezelf uit te dagen, te onderzoeken, te ontdekken, te bouwen, te testen, te verbeteren … In onze STEM-projecten start je altijd vanuit een probleemstelling of een onderzoeksvraag. Je wordt uitgedaagd om hiervoor een wetenschappelijke oplossing te bedenken. Geef jij ook je STEM aan technologische wetenschappen?",
      "hours": 2,
      "types": [
        "fix",
        "flex"
      ],
      "active": false,
      "selectable": true
    },
    {
      "id": 18,
      "name": "Uitbreiding algemene vorming",
      "text": "",
      "hours": 2,
      "types": [
        "fix"
      ],
      "active": false,
      "selectable": true
    }
  ],
  "grades": [
    {
      "name": "Leerjaar 1A",
      "active": false,
      "packets": [
        {
          "id": 1,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            },
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            1
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 2,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            2,
            4,
            5
          ],
          "hours": 4,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 3,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            },
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            1,
            5
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 4,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            },
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            1,
            5
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 5,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            },
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            2,
            4,
            5
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 6,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            },
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            2,
            5
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 7,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            },
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            3
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 8,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            },
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            3,
            5
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 9,
          "types": [
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [

            4,
            5
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 10,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            },
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            1,
            5
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 11,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            5
          ],
          "hours": 5,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 12,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            },
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            2
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 15,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            1
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 16,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            },
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            1
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 17,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            },
            {
              "name": "flex",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            3
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 18,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            4
          ],
          "hours": 2,
          "selectable": true,
          "active": false,
          "type": null
        }
      ]
    },
    {
      "name": "Leerjaar 1B",
      "active": false,
      "packets": [
        {
          "id": 13,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            1,
            5
          ],
          "hours": 4,
          "selectable": true,
          "active": false,
          "type": null
        },
        {
          "id": 14,
          "types": [
            {
              "name": "fix",
              "selectable": true,
              "active": false
            }
          ],
          "schools": [
            3,
            5
          ],
          "hours": 4,
          "selectable": true,
          "active": false,
          "type": null
        }
      ]
    }
  ]
}

