import datakeuzepagina from './dataKeuzepagina.json';
import $ from 'jquery'
import Vue from 'vue/dist/vue.min';


$(() => {
    $('#keuzepagina').tooltip({
        selector: '[data-toggle="tooltip"]'
    })
})

let app = new Vue({
    el: '#keuzepagina',
    data: function () {
        return datakeuzepagina
    },
    delimiters: ['<%', '%>'],
    methods: {
        getPacket: function (id) {
            return this.packets.find(function (packet) {
                return packet.id == id;
            });
        },
        getActiveType: function (packet) {

            return packet.types.find(function (type) {
                return type.active == true;
            })
        },
        getSchool: function (id) {
            return this.schools.find(function (school) {
                return school.id === id;
            });
        },
        getPacketsForSchool: function (schoolid) {
            return this.schoolPackets[schoolid];
        },
        changeGrade: function (grade) {

            let vm = this;
            this.grades.map(function (grade) {
                grade.active = false;
                grade.packets.map(function (packet) {
                    packet.active = false;
                    packet.selectable = true;
                    packet.types.map(function (type) {
                        type.active = false;
                        type.selectable = true;
                    });

                });
            });
            grade.active = true;
            this.activeGrade = grade;

            this.packets.map(function (packet) {
                packet.active = false;
            });

            this.schoolPackets = {};

            $('[data-toggle="tooltip"]').tooltip();

            Vue.nextTick(function () {
                vm.steps = 2;
            })


        },
        addType: function (id, type) {
            //--On click on packet
            this.activeGrade.packets.map(function (packet) {
                if (packet.id == id)
                {
                    if (packet.selectable == true && type.selectable == true)
                    {
                        packet.active = true;
                        packet.type = type.name;

                        //--Desactivate all types of the packet
                        packet.types.map(function (typePacket) {
                            typePacket.active = false;
                            typePacket.selectable = false;
                        })
                        //--Set current type to active
                        type.active = true;
                        type.selectable = true;
                    }
                }
            });
            this.disableTypes();
        },
        removeType: function (id, type) {
            this.steps = 2;
            //--On click on packet
            this.activeGrade.packets.map(function (packet) {
                if (packet.id == id)
                {
                    packet.active = false;
                    packet.type = null;
                    type.active = false;
                }
            });
            if (type.name == 'fix')
            {
                this.schools.map(function (school) {
                    school.fix = false;
                });
            }

            this.disableTypes();
            if (Object.keys(this.schoolPackets).length > 0)
            {
                for (let prop in this.schoolPackets)
                {
                    this.schoolPackets[prop].splice(this.schoolPackets[prop].indexOf(id), 1);

                    if (this.schoolPackets[prop].length == 0)
                    {
                        delete this.schoolPackets[prop];

                    }

                }
            }
        },

        getSelectedTypes: function (typeName) {
            let types = [];
            if (this.activePackets)
            {
                this.activePackets.map(function (activePacket) {
                    types = activePacket.types.filter(function (type) {
                        return type.active == true && type.name == typeName;
                    });
                });
            }
            return types;
        },
        getSelectableTypes: function (packet) {
            let types = [];
            types = packet.types.filter(function (type) {
                return type.selectable == true;
            });

            return types;
        },

        disableTypes: function () {

            //--Disable the types after adding or removing one
            let vm = this;
            let noPacketActive = {};
            this.activeGrade.packets.map(function (packet) {
                //--Enable packet
                packet.selectable = true;
                packet.types.map(function (type) {
                    type.selectable = true;
                });

                //--Check if packet is not active at this time
                if (packet.active == false)
                {
                    //--Loop all the types
                    packet.types.map(function (type) {
                        //--Set multiplier for the fixes
                        let multiplier = 1;
                        //--Check if type is fix
                        if (type.name == "fix")
                        {
                            type.selectable = vm.getSelectedTypes("fix").length <= 0;

                            //--Check if fix is already selected
                            multiplier = 2;
                        }

                        //--Check if hours of the packet (with multiplier) is les or equal to the available times
                        if ((packet.hours * multiplier) > vm.maxhours - vm.totalHoursSelected && (packet.hours < 5 || vm.totalHoursSelected != 0))
                        {
                            //--Set selectable to false
                            type.selectable = false;
                        }

                    });
                    //--Check if no types are selectable
                    packet.selectable = vm.getSelectableTypes(packet).length != 0;
                }
                else
                {
                    noPacketActive = false;
                    packet.types.map(function (type) {
                        if (!type.active)
                        {
                            type.selectable = false;
                        }
                    });
                }

                /*if (noPacketActive)
                {
                    this.schoolPackets = {};
                }*/
            })
        },
        getActivePacketsId: function () {
            let packetIds = [];
            //--Get active packets
            this.activePackets.map(function (packet) {
                packetIds.push(packet.id);
            });
            return packetIds;
        },
        calculatePossibleSchools: function () {

            //--disable all schools
            this.schools.map(function (school) {
                school.selected = false;
            });

            let vm = this;
            let schoolgroups = this.getSchoolGroups();
            this.setPacketsBySchoolgroup();

            //--Check if active grade is set
            if (this.activeGrade.packets)
            {

                //--Loop all the packets
                this.activeGrade.packets.map(function (packet) {

                    //--Check if packet is in active packets
                    if (vm.getActivePacketsId().includes(packet.id))
                    {
                        //--Look for school
                        //--Loop all the schoolgroups

                        for (let schoolgroup in schoolgroups)
                        {
                            if (!packet.schoolgroups.find(function (packetSchoolgroup) {
                                return packetSchoolgroup == schoolgroup;
                            }))
                            {
                                delete schoolgroups[schoolgroup];
                            }
                        }
                        packet.schools.map(function (schoolId) {
                            let school = vm.getSchool(schoolId);
                            school.selected = school.schoolgroup in schoolgroups;
                            if (packet.type == "fix")
                            {
                                school.fix = true;
                            }
                            else
                            {
                                school.fix = school.fix;
                            }
                            if (!Array.isArray(vm.schoolPackets[schoolId]))
                            {
                                vm.schoolPackets[schoolId] = [];
                            }

                            if (!vm.schoolPackets[schoolId].includes(packet.id))
                            {
                                vm.schoolPackets[schoolId].push(packet.id)
                            }

                        });
                    }
                });

                this.schools.map(function (school) {
                    school.selected = school.schoolgroup in schoolgroups && school.selected;
                })
            }
        },
        getSchoolGroups: function () {
            let schoolgroups = {};
            this.schools.map(function (school) {
                if (!Array.isArray(schoolgroups[school.schoolgroup]))
                {
                    schoolgroups[school.schoolgroup] = [];
                }
                schoolgroups[school.schoolgroup].push(school);
            });

            return schoolgroups;
        },
        setPacketsBySchoolgroup: function () {
            let vm = this;
            if (this.activeGrade.packets)
            {

                this.activeGrade.packets.map(function (packet) {
                    //--Set empty array for the schoolgroup
                    packet.schoolgroups = [];

                    packet.schools.map(function (packetSchool) {
                        let school = vm.getSchool(packetSchool);
                        if (!packet.schoolgroups.includes(school.schoolgroup))
                        {
                            packet.schoolgroups.push(school.schoolgroup);
                        }
                    })
                });
            }
        },
        resetGrades: function () {
            let vm = this;
            this.grades.map(function (grade) {
                grade.active = false;
                grade.packets.map(function (packet) {
                    packet.active = false;
                    packet.selectable = true;
                    packet.types.map(function (type) {
                        type.active = false;
                        type.selectable = true;
                    });

                });
            });
            this.schools.map(function (school) {
                school.fix = false;
            });
            this.activeGrade = {};
        }
    },
    computed: {
        //--Show end result
        activePackets: function () {

            let packets = [];
            if (this.activeGrade.packets)
            {
                //--Get active packets
                packets = this.activeGrade.packets.filter(function (packet) {
                    return packet.active;
                })
            }
            return packets;
        },
        activeSchools: function () {
            let vm = this;

            this.calculatePossibleSchools();

            //--Get active packets
            let schools = this.schools.filter(function (school) {
                return school.selected;
            });
            let schoolGroups = {};
            schools.map(function (school) {
                let addSchool = true;

                if (school.selected == true)
                {
                    //--Check if fix is enabled, if true, bernardus cant be selected
                    if (vm.fixSchools.length > 0 && school.id == 5 && vm.maxhours <= vm.totalHoursSelected && vm.activePackets.length > 1)
                    {
                        addSchool = false;
                    }
                    if (vm.fixSchools.length == 0 && vm.activePackets.length >= 4 && school.id != 5)
                    {
                        addSchool = false;
                    }

                    if (addSchool == true)
                    {
                        if (!Array.isArray(schoolGroups[school.schoolgroup]))
                        {
                            schoolGroups[school.schoolgroup] = [];
                        }
                        schoolGroups[school.schoolgroup].push(school)
                    }
                }
            });

            return schoolGroups;
        },
        fixSchools: function () {
            this.calculatePossibleSchools();
            let vm = this;

            //--Get active packets
            let schools = this.schools.filter(function (school) {

                return school.fix && school.selected && (school.id != 5 || vm.activeGrade.name == 'Leerjaar 1B' || vm.activePackets.length == 1);
            })
            return schools;
        },
        totalHoursSelected: function () {
            let packets = this.activePackets;
            let hours = 0;
            let vm = this;
            packets.map(function (packet) {
                let type = vm.getActiveType(packet);
                if (type.name == "fix")
                {
                    //--Multiply x 2 for fix (whole year)
                    hours += (packet.hours * 2);
                }
                else
                {
                    hours += packet.hours;
                }
            });

            if (hours >= this.maxhours)
            {
                Vue.nextTick(function () {
                    vm.steps = 3;
                })

            }

            return hours;
        }
    },
    filters: {},
    watch: {
        steps: function () {
            let location = '';
            /*if (this.steps == 1)
            {
                //location = "grades";
            }
            else*/
            if (this.steps == 2)
            {
                location = "packets";
            }
            else if (this.steps == 3)
            {
                location = "schools";
            }

            if (location != '')
            {
                window.scroll({
                    top: document.getElementById(location).offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }

        }
    }
})
