$(document).ready(function () {
    if ($.fn.slick)
    {

        $('.slider').slick({
            arrows: false,
            autoplay: true,
            dots: !1,
            fade: !0,
            lazyLoad: 'ondemand',
        });
    }
});